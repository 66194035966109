import Rectangle4 from './Components/Rectangle4';
import Rectangle6 from './Components/Rectangle6';
import Rectange7 from './Components/Rectange7';
import Rectangle9 from './Components/Rectangle9';


function App() {
  return (
 
    <>
    <Rectangle4/>
     <Rectangle6/>
    <Rectange7/>
    <Rectangle9/>
    <div style={{height:'200px'}}>

    </div>

    </>
  )
}

export default App;
