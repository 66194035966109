import React from 'react'

const Rectangle4 = () => {
    return (
        <div className="rectangle4">
            <h3 style={{margin:'0px'}}>Vidyayan</h3>
            
        </div>
    )
}

export default Rectangle4
